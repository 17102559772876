import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Alert from '../../components/Popups/ImageAlert'; // 위에서 만든 Alert 컴포넌트
import Layout from "../../layouts";

import RecruitList from "./RecruitList";
import RecommendRecruitList from "./RecommendRecruitList";
import { MyBoard } from "../../components/Recruit";
import { Banner } from "components/commons";

const RecruitPage = () => {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        // 페이지 로드 시 쿠키 확인
        const popupCookie = Cookies.get('hidePopup');
        if (!popupCookie) {
            setShowPopup(true); // 쿠키가 없으면 팝업 표시
        }
    }, []);

    const handleConfirm = () => {
        setShowPopup(false); // 팝업 닫기
    };

    const handleHideToday = () => {
        Cookies.set('hidePopup', 'true', { expires: 1 }); // 1일간 쿠키 설정
        setShowPopup(false); // 팝업 닫기
    };

    return (
        <Layout>
            <section className="main-head">
                <div className="inner wrap">
                    {showPopup && (
                        <Alert
                            imageUrl="/banner_main/100th_KDA_Banner.png" // 이미지 URL 전달
                            imageLink="http://kda100.or.kr" // 이미지 클릭 시 이동할 링크
                            yesEvent={handleConfirm}
                            noEvent={handleHideToday}
                            onClose={() => setShowPopup(false)}
                        />
                    )}

                    <RecommendRecruitList />
                    <MyBoard />
                </div>
            </section>
            <div className="inner" style={{ marginTop: 30 }}>
                <Banner type="MAIN" />
            </div>
            <RecruitList />
        </Layout>
    );
};

export default RecruitPage;
