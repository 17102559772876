import React from "react";
import Cookies from "js-cookie";

interface ImageAlert {
    imageUrl: string; // 이미지 URL
    imageLink: string; // 이미지 클릭 시 이동할 링크
    yesEvent: () => void; // "확인" 버튼 이벤트
    noEvent: () => void; // "오늘 하루 보지 않기" 버튼 이벤트
    commonCallback?: () => void; // 공통 콜백 함수 (옵션)
    onClose: () => void;
}

const isSupportedEnvironment = (): boolean => {
    const userAgent = navigator.userAgent.toLowerCase();

    // 모바일 환경 체크
    const isMobile = /iphone|ipad|ipod|android/.test(userAgent);

    // 네이버 앱 체크 (inapp 포함)
    const isNaverApp = userAgent.includes("naver") && userAgent.includes("inapp");

    // iOS 네이티브 앱 체크
    const isIOSNativeApp = /iphone|ipad|ipod/.test(userAgent) && !isNaverApp && !userAgent.includes("safari");

    // 사파리 브라우저 체크
    const isSafari =
        userAgent.includes("safari") &&
        !userAgent.includes("chrome") &&
        !userAgent.includes("android");

    // 데스크탑 환경 체크
    const isDesktop =
        !isMobile &&
        (userAgent.includes("windows") ||
            userAgent.includes("macintosh") ||
            userAgent.includes("linux"));

    // 웹뷰 환경 체크 (inapp이 포함되었고 네이버 앱 제외)
    const isInApp = /inapp/.test(userAgent);
    const isWebViewExceptNaver = isInApp && !isNaverApp;

    // 일반 웹뷰 환경 체크
    const isGeneralWebView = (() => {
        // iOS 웹뷰 감지
        const isIOSWebView =
            /version\/[\d.]+.*mobile.*safari/.test(userAgent) &&
            !userAgent.includes("safari");

        // Android 웹뷰 감지
        const isAndroidWebView =
            /wv/.test(userAgent) ||
            (userAgent.includes("android") &&
                userAgent.includes("version") &&
                !userAgent.includes("chrome"));

        return isIOSWebView || isAndroidWebView;
    })();

    // 팝업을 표시할 조건:
    // - 데스크탑 환경
    // - 네이버 앱
    // - 사파리 브라우저
    // - 일반 모바일 웹 (웹뷰 제외)
    // - 네이티브 앱일 경우 팝업 차단 (iOS 기기에서 네이티브 앱인 경우)
    return (
        (isDesktop || // 데스크탑 환경
            isNaverApp || // 네이버 앱
            isSafari || // 사파리 브라우저
            (!isGeneralWebView && isMobile)) // 모바일 웹 (웹뷰 제외)
        && !isIOSNativeApp // iOS 네이티브 앱에서 팝업 차단
        && !isWebViewExceptNaver // 웹뷰에서는 네이버를 제외하고 팝업 차단
    );
};

const isDesktopEnvironment = (): boolean => {
    const userAgent = navigator.userAgent.toLowerCase();

    // 모바일 환경 체크
    const isMobile = /iphone|ipad|ipod|android/.test(userAgent);

    // 데스크톱 환경 체크
    const isDesktopCheck =
        !isMobile &&
        (userAgent.includes("windows") ||
            userAgent.includes("macintosh") ||
            userAgent.includes("linux"));

    return isDesktopCheck; // 데스크톱 환경일 때 true 반환
};

const ImageAlert = ({ imageUrl, imageLink, yesEvent, noEvent, commonCallback, onClose }: ImageAlert) => {
    // 앱 환경에서는 팝업 표시하지 않음
    if (!isSupportedEnvironment()) {
        return null;
    }

    // "오늘 하루 보지 않기" 버튼 클릭 시 쿠키 설정
    const handleHidePopup = () => {
        Cookies.set('hidePopup', 'true', { expires: 1 }); // 1일 동안 쿠키 저장
        noEvent();
        if (typeof commonCallback === "function") commonCallback();
    };

    // 이미지 클릭 시 링크로 이동
    const handleImageClick = () => {
        window.open(imageLink, "_blank"); // 새 탭에서 링크 열기
    };

    const isDesktopCheck = isDesktopEnvironment();

    return (
        <article
            className="popup alert-popup confirm-button"
            style={{ display: "block", backgroundColor : "rgba(0, 0, 0, 0.4)" }}
        >
            <div className="popup-wrap">
                <div className="popup-head">
                    <div
                        className="head-txt-box"
                        style={{
                            marginInlineEnd: "auto",
                            display: isDesktopCheck ? "block" : "none",
                        }}
                    >
                        알림
                    </div>

                    <div className="mobile-head-box">
                        <div className="mobile-head-txt">100주년 기념 학술대회 안내</div>
                    </div>

                    <button className="close-btn" onClick={onClose}></button>
                </div>
                <div className="popup-body">
                    {/* 이미지 클릭 시 바로가기 */}
                    <img
                        src={imageUrl}
                        alt="Popup Image"
                        style={{width: "100%"}}
                        onClick={handleImageClick} // 클릭 이벤트 추가
                    />

                    <div className="popup-alert-Imgbtns">
                        <button
                            onClick={() => {
                                yesEvent();
                                if (typeof commonCallback === "function") commonCallback();
                            }}
                        >
                            확인
                        </button>
                        <button onClick={handleHidePopup}>
                            오늘 하루 보지 않기
                        </button>
                    </div>
                </div>
            </div>
        </article>
    );
};

export default ImageAlert;
